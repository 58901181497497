import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { connect } from "react-redux";
import { RelayZCTSettingModel, UpdateResult } from '../../../models';
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import { changeDisabledPollingBtn, formatDateTime, showUnit } from '../../../utils';
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SelectRelayZCTSettingDialog from '../../dialogs/SelectRelayZCTSettingDialog';

const NO_OF_POLLING_TOPIC = 21;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
    isDisbleUpdateBtn: true,
}

type RelayZCTSettingProps = {
    defaultData: RelayZCTSettingModel[] | undefined;
};

const ZCT_RELAY_OPTION = [
    {
        label: "4500：1",
        value: "0001"
    },
    {
        label: "2000：1",
        value: "0002"
    },
    {
        label: "OTG-AA42",
        value: "0003"
    },
    {
        label: "CTF-05A",
        value: "0004"
    },
]

const columnKeyStyle= {
    width: '30%',
    border: 'none',
}

const columnValueStyle= {
    width: '20%',
    border: 'none',
}

export type PureRelayZCTSettingProps = RelayZCTSettingProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

const fetchId = "RELAY_ZCT_SETTING_FETCH";
export const RelayZCTSetting = (props: PureRelayZCTSettingProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isEdit, isRefreshGroup, isDisbleRefeshBtn, isDisbleUpdateBtn } = props;
    const { onError, onUpdate, onRefresh, onUpdateSuccess, onUpdateError, isRefreshAllCompleted, setIsEdit, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [updateData, setUpdateData] = React.useState<any>();
    const [relayZCTSetting0, setRelayZCTSetting0] = React.useState<RelayZCTSettingModel>(fetchState.data);
    const [relayZCTSetting1, setRelayZCTSetting1] = React.useState<RelayZCTSettingModel>(fetchState.data);
    const [relayZCTSetting2, setRelayZCTSetting2] = React.useState<RelayZCTSettingModel>(fetchState.data);
    const [relayZCTSetting3, setRelayZCTSetting3] = React.useState<RelayZCTSettingModel>(fetchState.data);
    const [relayZCTSetting4, setRelayZCTSetting4] = React.useState<RelayZCTSettingModel>(fetchState.data);
    const [editData, setEditData] = React.useState<RelayZCTSettingModel>();
    const [fetchLoadingDevice0, setFetchLoadingDevice0] = React.useState(false);
    const [fetchLoadingDevice1, setFetchLoadingDevice1] = React.useState(false);
    const [fetchLoadingDevice2, setFetchLoadingDevice2] = React.useState(false);
    const [fetchLoadingDevice3, setFetchLoadingDevice3] = React.useState(false);
    const [fetchLoadingDevice4, setFetchLoadingDevice4] = React.useState(false);
    const [editFlg, setEditFlg] = React.useState(new Array(5).fill('').map(() => false));
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const [isRefreshSuccess0, setIsRefreshSuccess0] = React.useState(false);
    const [isRefreshSuccess1, setIsRefreshSuccess1] = React.useState(false);
    const [isRefreshSuccess2, setIsRefreshSuccess2] = React.useState(false);
    const [isRefreshSuccess3, setIsRefreshSuccess3] = React.useState(false);
    const [isRefreshSuccess4, setIsRefreshSuccess4] = React.useState(false);

    const deviceNos = ['00', '01', '02', '03', '04'];
    const deviceNames = ['本体', '子機1', '子機2', '子機3', '子機4'];

    // パラメータ設定
    const handleFetchParams = (deviceNo: string) => {
        const params = new Map<string, any>();
        const code = "PM";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", deviceNo);
        return params;
    }

    // 重漏電設定取得成功時
    const handleFetchSuccess = (data: any, action: string, isClickedRefreshBtn: boolean) => {
        setEditFlg(changeDisabledPollingBtn(false, data.device_no, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        if (data) {
            const resData = {
                mainid: data.mainid,
                deviceNo: data.device_no,
                zctType1: data.zct_type1,
                zctType2: data.zct_type2,
                zctType3: data.zct_type3,
                zctType4: data.zct_type4,
                zctType5: data.zct_type5,
                zctType6: data.zct_type6,
                ctRatio1: data.ct_ratio1,
                ctRatio2: data.ct_ratio2,
                ctRatio3: data.ct_ratio3,
                ctRatio4: data.ct_ratio4,
                ctRatio5: data.ct_ratio5,
                ctRatio6: data.ct_ratio6,                
                lastGetDatetime: data.last_get_datetime,
            } as RelayZCTSettingModel;

            switch (data.device_no) {
                case deviceNos[0]:
                    setRelayZCTSetting0(resData);
                    setFetchLoadingDevice0(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                    isClickedRefreshBtn && setIsRefreshSuccess0(true);
                    break;
                case deviceNos[1]:
                    setRelayZCTSetting1(resData);
                    setFetchLoadingDevice1(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                    isClickedRefreshBtn && setIsRefreshSuccess1(true);
                    break;
                case deviceNos[2]:
                    setRelayZCTSetting2(resData);
                    setFetchLoadingDevice2(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                    isClickedRefreshBtn && setIsRefreshSuccess2(true);
                    break;
                case deviceNos[3]:
                    setRelayZCTSetting3(resData);
                    setFetchLoadingDevice3(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                    isClickedRefreshBtn && setIsRefreshSuccess3(true);
                    break;
                case deviceNos[4]:
                    setRelayZCTSetting4(resData);
                    setFetchLoadingDevice4(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                    isClickedRefreshBtn && setIsRefreshSuccess4(true);
                    setRefreshGroup(false, fetchId);
                    break;
            }

            if (action === 'edit') {
                setEditData(resData);
                setOpenSelectDialog(true);
            }
        }
    };

    const RelayZCTTypeName = (value:any) => {
        if(value){
            const item = ZCT_RELAY_OPTION.find((item:any) => item.value == value);
            if(item){
                return item.label;
            }
            return "";
        }
        return "";
    }

    const handleError = (success: boolean, data: any, error: any, deviceNo: string) => {
        onError(success, data, error, deviceNames[Number(deviceNo)]);
        setEditFlg(changeDisabledPollingBtn(false, deviceNo, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                setIsRefreshSuccess4(false);
                break;
        }
    }

    const handleRefresh = (deviceNo: string) => {
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
    };

    const handleRefreshSuccess = (data: any, deviceNo: string) => {
        const deviceIndex = deviceNos.findIndex((element) => element === data.device_no);
        editFlg[deviceIndex] ? handleFetchSuccess(data, 'edit', true) : handleFetchSuccess(data, 'view', true);
    }

    const [updateDialogTitle, setUpdateDialogTitle] = React.useState('');
    const handleEdit = (deviceNo: string) => {
        setEditFlg(changeDisabledPollingBtn(true, deviceNo, deviceNos, editFlg));
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
        switch (deviceNo) {
            case deviceNos[0]:
                setUpdateDialogTitle('Relay・ZCT 設定/本体');
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setUpdateDialogTitle('Relay・ZCT 設定/子機1');
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setUpdateDialogTitle('Relay・ZCT 設定/子機2');
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setUpdateDialogTitle('Relay・ZCT 設定/子機3');
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setUpdateDialogTitle('Relay・ZCT 設定/子機4');
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateRelayZCTSettingOk = (request: any) => {
        setOpenSelectDialog(false);
        const code = "PN";
        const requestData = {
            mainid: request.mainid,
            device_no: request.device_no,
            zct_type1: request.zct_type1,
            zct_type2: request.zct_type2,
            zct_type3: request.zct_type3,
            zct_type4: request.zct_type4,
            zct_type5: request.zct_type5,
            zct_type6: request.zct_type6,
            ct_ratio1: request.ct_ratio1,
            ct_ratio2: request.ct_ratio2,
            ct_ratio3: request.ct_ratio3,
            ct_ratio4: request.ct_ratio4,
            ct_ratio5: request.ct_ratio5,
            ct_ratio6: request.ct_ratio6,
        }
        onUpdate(code, requestData);
        setUpdateData(requestData);
        switch (request.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateRelayZCTSettingCancel = () => {
        setOpenSelectDialog(false);
    };

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        const result = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            resultCode: data.result_code,
        } as UpdateResult;
        if (result.resultCode === '0') {
            handleFetchSuccess({...updateData,last_get_datetime: formatDateTime(new Date())}, 'view', true);
            onUpdateSuccess(deviceNames[Number(result.deviceNo)]);
        } else {
            handleUpdateError(success, data, error);
        }
    }

    const handleUpdateError = (success: boolean, data: any, error: any) => {
        switch (updateData.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                setIsRefreshSuccess4(true);
                break;
        }
        onUpdateError(success, data, error, deviceNames[Number(updateData.device_no)]);
    }


    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh(deviceNos[0]);
        }
        if (isRefreshGroup === true) {
            handleRefresh(deviceNos[0]);
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, loadingRefreshAll, isRefreshGroup]);

    const handleDisabledRefreshAll = () => {
        if (!fetchLoadingDevice0 && !fetchLoadingDevice1 && !fetchLoadingDevice2 && !fetchLoadingDevice3 && !fetchLoadingDevice4) {
            setDisabledRefreshGroupBtn(false, fetchId);
        } else {
            setDisabledRefreshGroupBtn(true, fetchId);
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevice0, fetchLoadingDevice1, fetchLoadingDevice2, fetchLoadingDevice3, fetchLoadingDevice4]);

    React.useEffect(() => {
        [...editFlg].find(item => item === true) === true ? setIsEdit(true) : setIsEdit(false);
        // eslint-disable-next-line
    }, [editFlg])

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, 'view', false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            {/* device00 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess0 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('00')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice0 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('00')} disabled={isDisbleRefeshBtn || editFlg[0] || fetchLoadingDevice0 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[0] === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Relay・ZCT 設定/本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice0 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類1</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting0?.zctType1)}</td>
                                    <td style={columnKeyStyle}>CT比1</td>
                                    <td style={columnValueStyle}>{relayZCTSetting0?.ctRatio1}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類2</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting0?.zctType2)}</td>
                                    <td style={columnKeyStyle}>CT比2</td>
                                    <td style={columnValueStyle}>{relayZCTSetting0?.ctRatio2}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類3</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting0?.zctType3)}</td>
                                    <td style={columnKeyStyle}>CT比3</td>
                                    <td style={columnValueStyle}>{relayZCTSetting0?.ctRatio3}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類4</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting0?.zctType4)}</td>
                                    <td style={columnKeyStyle}>CT比4</td>
                                    <td style={columnValueStyle}>{relayZCTSetting0?.ctRatio4}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類5</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting0?.zctType5)}</td>
                                    <td style={columnKeyStyle}>CT比5</td>
                                    <td style={columnValueStyle}>{relayZCTSetting0?.ctRatio5}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類6</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting0?.zctType6)}</td>
                                    <td style={columnKeyStyle}>CT比6</td>
                                    <td style={columnValueStyle}>{relayZCTSetting0?.ctRatio6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{relayZCTSetting0?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device01 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess1 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('01')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice1 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('01')} disabled={isDisbleRefeshBtn || editFlg[1] || fetchLoadingDevice1 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[1] === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Relay・ZCT 設定/子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice1 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類1</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting1?.zctType1)}</td>
                                    <td style={columnKeyStyle}>CT比1</td>
                                    <td style={columnValueStyle}>{relayZCTSetting1?.ctRatio1}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類2</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting1?.zctType2)}</td>
                                    <td style={columnKeyStyle}>CT比2</td>
                                    <td style={columnValueStyle}>{relayZCTSetting1?.ctRatio2}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類3</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting1?.zctType3)}</td>
                                    <td style={columnKeyStyle}>CT比3</td>
                                    <td style={columnValueStyle}>{relayZCTSetting1?.ctRatio3}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類4</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting1?.zctType4)}</td>
                                    <td style={columnKeyStyle}>CT比4</td>
                                    <td style={columnValueStyle}>{relayZCTSetting1?.ctRatio4}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類5</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting1?.zctType5)}</td>
                                    <td style={columnKeyStyle}>CT比5</td>
                                    <td style={columnValueStyle}>{relayZCTSetting1?.ctRatio5}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類6</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting1?.zctType6)}</td>
                                    <td style={columnKeyStyle}>CT比6</td>
                                    <td style={columnValueStyle}>{relayZCTSetting1?.ctRatio6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{relayZCTSetting1?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card>

            {/* device02 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess2 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('02')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice2 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('02')} disabled={isDisbleRefeshBtn || editFlg[2] || fetchLoadingDevice2 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[2] === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Relay・ZCT 設定/子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice2 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類1</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting2?.zctType1)}</td>
                                    <td style={columnKeyStyle}>CT比1</td>
                                    <td style={columnValueStyle}>{relayZCTSetting2?.ctRatio1}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類2</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting2?.zctType2)}</td>
                                    <td style={columnKeyStyle}>CT比2</td>
                                    <td style={columnValueStyle}>{relayZCTSetting2?.ctRatio2}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類3</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting2?.zctType3)}</td>
                                    <td style={columnKeyStyle}>CT比3</td>
                                    <td style={columnValueStyle}>{relayZCTSetting2?.ctRatio3}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類4</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting2?.zctType4)}</td>
                                    <td style={columnKeyStyle}>CT比4</td>
                                    <td style={columnValueStyle}>{relayZCTSetting2?.ctRatio4}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類5</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting2?.zctType5)}</td>
                                    <td style={columnKeyStyle}>CT比5</td>
                                    <td style={columnValueStyle}>{relayZCTSetting2?.ctRatio5}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類6</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting2?.zctType6)}</td>
                                    <td style={columnKeyStyle}>CT比6</td>
                                    <td style={columnValueStyle}>{relayZCTSetting2?.ctRatio6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{relayZCTSetting2?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card>

            {/* device03 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess3 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('03')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice3 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('03')} disabled={isDisbleRefeshBtn || editFlg[3] || fetchLoadingDevice3 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[3] === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Relay・ZCT 設定/子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice3 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類1</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting3?.zctType1)}</td>
                                    <td style={columnKeyStyle}>CT比1</td>
                                    <td style={columnValueStyle}>{relayZCTSetting3?.ctRatio1}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類2</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting3?.zctType2)}</td>
                                    <td style={columnKeyStyle}>CT比2</td>
                                    <td style={columnValueStyle}>{relayZCTSetting3?.ctRatio2}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類3</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting3?.zctType3)}</td>
                                    <td style={columnKeyStyle}>CT比3</td>
                                    <td style={columnValueStyle}>{relayZCTSetting3?.ctRatio3}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類4</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting3?.zctType4)}</td>
                                    <td style={columnKeyStyle}>CT比4</td>
                                    <td style={columnValueStyle}>{relayZCTSetting3?.ctRatio4}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類5</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting3?.zctType5)}</td>
                                    <td style={columnKeyStyle}>CT比5</td>
                                    <td style={columnValueStyle}>{relayZCTSetting3?.ctRatio5}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類6</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting3?.zctType6)}</td>
                                    <td style={columnKeyStyle}>CT比6</td>
                                    <td style={columnValueStyle}>{relayZCTSetting3?.ctRatio6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{relayZCTSetting3?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card>

            {/* device04 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess4 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('04')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice4 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('04')} disabled={isDisbleRefeshBtn || editFlg[4] || fetchLoadingDevice4 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[4] === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Relay・ZCT 設定/子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice4 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ZCT/Relay種類6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類1</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting4?.zctType1)}</td>
                                    <td style={columnKeyStyle}>CT比1</td>
                                    <td style={columnValueStyle}>{relayZCTSetting4?.ctRatio1}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類2</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting4?.zctType2)}</td>
                                    <td style={columnKeyStyle}>CT比2</td>
                                    <td style={columnValueStyle}>{relayZCTSetting4?.ctRatio2}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類3</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting4?.zctType3)}</td>
                                    <td style={columnKeyStyle}>CT比3</td>
                                    <td style={columnValueStyle}>{relayZCTSetting4?.ctRatio3}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類4</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting4?.zctType4)}</td>
                                    <td style={columnKeyStyle}>CT比4</td>
                                    <td style={columnValueStyle}>{relayZCTSetting4?.ctRatio4}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類5</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting4?.zctType5)}</td>
                                    <td style={columnKeyStyle}>CT比5</td>
                                    <td style={columnValueStyle}>{relayZCTSetting4?.ctRatio5}</td>
                                </tr>
                                <tr>
                                    <td style={columnKeyStyle}>ZCT/Relay種類6</td>
                                    <td style={columnValueStyle}>{RelayZCTTypeName(relayZCTSetting4?.zctType6)}</td>
                                    <td style={columnKeyStyle}>CT比6</td>
                                    <td style={columnValueStyle}>{relayZCTSetting4?.ctRatio6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{relayZCTSetting4?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card>
            {
                openSelectDialog ? (
                    <SelectRelayZCTSettingDialog
                        isOpen={true}
                        onOK={(data) => handleUpdateRelayZCTSettingOk(data)}
                        onCancel={handleUpdateRelayZCTSettingCancel}
                        data={editData}
                        dialogTitle={updateDialogTitle}
                    />
                ) : (
                    <div />
                )
            }
            <FetchHelper fetchId={fetchId + deviceNos[0]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[0]) : handleError(success, data, error, deviceNos[0])} />
            <FetchHelper fetchId={fetchId + deviceNos[1]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[1]) : handleError(success, data, error, deviceNos[1])} />
            <FetchHelper fetchId={fetchId + deviceNos[2]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[2]) : handleError(success, data, error, deviceNos[2])} />
            <FetchHelper fetchId={fetchId + deviceNos[3]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[3]) : handleError(success, data, error, deviceNos[3])} />
            <FetchHelper fetchId={fetchId + deviceNos[4]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[4]) : handleError(success, data, error, deviceNos[4])} />
        <FetchHelper fetchId="UPDATE_RELAY_ZCT_SETTING" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(success, data, error) : handleUpdateError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
onUpdate: (code: string, data: any) => actionCreators.fetch("UPDATE_RELAY_ZCT_SETTING", `/polling/set?code=${code}`, 'POST', data, false, true),
    onRefresh: (params: any, fetchId: string) => actionCreators.fetch(fetchId, `/polling/get?code=PM&mainid=${params.get("mainid")}&device_no=${params.get("device_no")}`, "GET", null),
    onError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `重漏電設定/${deviceName}取得`,
            body: "重漏電設定情報取得できませんでした。",
        }),
    onUpdateSuccess: (deviceName: string) =>
        actionCreators.showMessage({
            type: "info",
            title: `重漏電設定/${deviceName}変更`,
            body: "重漏電設定を変更しました。",
        }),
    onUpdateError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `重漏電設定/${deviceName}変更`,
            body: "重漏電設定を変更できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RelayZCTSetting);

