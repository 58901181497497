import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { customerLabelName } from "../../../statics";

// 検索フォーム
export interface ItemForm {
    "id": string;
    "name": string;
    "name_formal":string;
    "kana": string;
    "zip": string;
    "address1": string;
    "address2": string;
    "tel": string;
    "account_copy_flag":boolean;
    "account_sfid":string|null;
    group_name:any|null;
    settingBase:any;
}

export type CustomersEditFormProps = {
    customers?: any;
    children?: any;
    authRole:any;
    mode: string;
    customerGroupOption:any;
    dataChooseFromBusinessPartner:any;
    baseList:any;
    onOK: (data: any) => void;
    onCancel: () => void;
    clearDataChoose: () => void
};

export const CustomersEdit = (props: CustomersEditFormProps) => {
    const { baseList, customerGroupOption, customers, mode, dataChooseFromBusinessPartner, authRole, clearDataChoose, onOK, onCancel, } = props;

    const [disabledSettingBase,setDisabledSettingBase] = useState<boolean>(mode == "insert" ? true : false);
    const [accountCopyFlag,setAccountCopyFlag] = useState<boolean>(false);
    const [baseListByCustomerGroup,setBaseListByCustomerGroup] = useState<any>();

    // 統括管理者
    const generalAdminstratorRole = useMemo(() => {
        return authRole?.role_list.find((item:any) => item.name == "統括管理者").role;
    },[authRole]);

    // 統括管理者以下( 6 >= target がTRUE)
    const isShow = useMemo(() => {
        return (6 >= authRole?.role || authRole?.role ==  generalAdminstratorRole)
    },[authRole,generalAdminstratorRole])

    // 初期値設定
    const initialValue: ItemForm = {
        id: "",
        name: "",
        name_formal: "",
        kana: "",
        zip: "",
        address1: "",
        address2: "",
        tel: "",
        group_name: "",
        account_copy_flag:false,
        settingBase: null,
        account_sfid:"",
    }

    const { handleSubmit, formState: { errors }, control, setValue, clearErrors, getValues } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    useEffect(() => {
        if(dataChooseFromBusinessPartner){
            setValue("kana",dataChooseFromBusinessPartner.kana ? dataChooseFromBusinessPartner.kana : "");
            setValue("name",dataChooseFromBusinessPartner.name ? dataChooseFromBusinessPartner.name : "");
            setValue("name_formal",dataChooseFromBusinessPartner.name ? dataChooseFromBusinessPartner.name : "");
            setValue("zip",dataChooseFromBusinessPartner.zip ? dataChooseFromBusinessPartner.zip : "");
            setValue("address1",dataChooseFromBusinessPartner.address1 ? dataChooseFromBusinessPartner.address1 : "");
            setValue("address2",dataChooseFromBusinessPartner.address2 ? dataChooseFromBusinessPartner.address2 : "");
            setValue("tel",dataChooseFromBusinessPartner.tel ? dataChooseFromBusinessPartner.tel : "");
            clearErrors("name");
            setValue("account_copy_flag", true);
            setAccountCopyFlag(true);
            setValue("account_sfid",dataChooseFromBusinessPartner.sfid ? dataChooseFromBusinessPartner.sfid : "");            
            clearDataChoose();
        }
    },[dataChooseFromBusinessPartner])

    useEffect(() => {
        if(customers && mode == "update"){
            setValue("id",customers.id);
            setValue("name",customers.name);
            setValue("name_formal",customers.name_formal);
            const initialValue = authRole.base_id_array.find((item:any) => item.base_id == customers.base_id);
            setValue("settingBase",initialValue);
            let account_copy_flag;
            if(customers.account_copy_flag == 1){
                account_copy_flag = true;
            }else{
                account_copy_flag = false;
            }
            setAccountCopyFlag(account_copy_flag);
            setValue("account_copy_flag",account_copy_flag);
            setValue("kana",customers.kana);
            setValue("zip",customers.zip);
            setValue("address1",customers.address1);
            setValue("address2",customers.address2);
            setValue("tel",customers.tel);
            setValue("group_name",customerGroupOption.find((item:any) => item.value == customers.group_id).value);
            setValue("account_sfid",customers.account_sfid);

            if(customers.group_id == undefined || customers.group_id == null){
                setDisabledSettingBase(true);
            }else{
                if(baseList){
                    const temp_data = baseList[`${customers.group_id}`];
                    if(!temp_data || temp_data && temp_data.base_id_array.length == 0){
                        setDisabledSettingBase(true);
                        setBaseListByCustomerGroup([]);
                    }else if(temp_data && temp_data.base_id_array.length > 0 ){
                        setBaseListByCustomerGroup(temp_data);
                        setValue("settingBase",temp_data.base_id_array.find((item:any) => item.base_id == customers.base_id));
                        setDisabledSettingBase(false);
                    }
                }else{
                    setDisabledSettingBase(true);
                }                
            }
        }
    },[customers])

    // Submitイベント
    const handleEditSubmit = (value: ItemForm) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ItemForm) => {
        let account_copy_flag;

        if(formValue.account_copy_flag){
            account_copy_flag = 1;
        }else{
            account_copy_flag = 0;
        }

        let request = {
            id: formValue.id,
            name: formValue.name,
            name_formal: formValue.name_formal,
            account_copy_flag: isShow ? account_copy_flag : 0,
            kana: formValue.kana,
            zip: formValue.zip,
            address1: formValue.address1,
            address2: formValue.address2,
            tel: formValue.tel,
            group_id:formValue.group_name,
            account_sfid: formValue.account_sfid,
        } as any;

        if(authRole && authRole.base_view_flag == 1){
            request = {...request, base_id: getValues().settingBase ? getValues().settingBase.base_id : null };
        }
         
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <div style={{ height: 'auto', width: '100%'}}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    {/* 基本情報 */}
                    <Stack spacing={2.5} style={{ marginLeft: '10px', width: '98%', position:"relative" }}>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                            <Grid sx={{pt:1.5}} container spacing={1} direction="row">
                                <FormControl size="small" fullWidth  error={!!errors?.group_name}>
                                    <InputLabel>組織グループ</InputLabel>
                                    <Controller
                                        control={control}
                                        name="group_name"
                                        rules={{ required: '必須項目です。選択してください。' }}
                                        render={({ field }) => (
                                            <>
                                                <Select
                                                    {...field}
                                                    error={!!errors?.group_name}
                                                    label={"組織グループ"}
                                                    onChange={(event) => {
                                                        const tempBaseList = baseList[`${event.target.value}`];
                                                        setValue("settingBase",null);
                                                        if(tempBaseList){
                                                            setBaseListByCustomerGroup(tempBaseList);
                                                            setDisabledSettingBase(false);
                                                        }else{
                                                            setDisabledSettingBase(true);
                                                        }
                                                        field.onChange(event);                                                        
                                                    }}
                                                >
                                                    {customerGroupOption.map((item:any) => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    !!errors?.group_name &&
                                                    <FormHelperText style={{ color: 'red' }}>
                                                        {errors?.group_name?.message}
                                                    </FormHelperText>
                                                }
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Stack>
                        {
                            authRole && authRole.base_view_flag == 1 && 
                            <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                                <Grid container spacing={1} direction="row">
                                    <Controller
                                        control={control}
                                        name="settingBase"
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                fullWidth
                                                disabled={disabledSettingBase}
                                                defaultValue={null}
                                                size="small"
                                                options={baseListByCustomerGroup?.base_id_array ?? []}
                                                isOptionEqualToValue={(option, value) => {return option.base_id === value.base_id}}
                                                getOptionLabel={(option) => option.base_name}
                                                onChange={(event, values) => {
                                                    field.onChange(values)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} sx={{background: disabledSettingBase ? "floralwhite" : "white"}} label="拠点"/>
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                        }
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="name"
                                    rules={{ required: '必須項目です。入力してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label={`${customerLabelName}-表記名`}
                                                size="small"
                                                error={!!errors?.name}
                                                helperText={errors?.name?.message}
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="name_formal"
                                    rules={{ required: '必須項目です。入力してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label={`${customerLabelName}-正式名`}
                                                size="small"
                                                error={!!errors?.name_formal}
                                                helperText={errors?.name_formal?.message}
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="kana"
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="カナ"
                                                size="small"
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                            />
                                        </>
                                    )}
                                />                                
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                            <Grid container spacing={1} direction="row" >
                                <Controller
                                    control={control}
                                    name="zip"
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="郵便番号"
                                                size="small"
                                                inputProps={{
                                                    maxLength:16
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                            <Grid container spacing={1} direction="row" >
                                <Controller
                                    control={control}
                                    name="address1"
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="住所1"
                                                size="small"
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                            <Grid container spacing={1} direction="row" >
                                <Controller
                                    control={control}
                                    name="address2"
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="住所2(建物名等)"
                                                size="small"
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                            />
                                        </>
                                    )}
                                />                                
                            </Grid>
                        </Stack>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                            <Grid container spacing={1} direction="row" >
                                <Controller
                                    control={control}
                                    name="tel"
                                    render={({ field }) => (
                                        <>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="電話番号"
                                                size="small"
                                                inputProps={{
                                                    maxLength:32
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        {
                            isShow &&
                            <>
                                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                                    <Grid container spacing={1} direction="row">
                                        <Controller
                                            name="account_copy_flag"
                                            control={control}
                                            render={({ field }: any) => (
                                                <FormControlLabel {...field} control={<Checkbox onChange={(event) => {
                                                    field.onChange(event);
                                                    setAccountCopyFlag(event.target.checked);
                                                    if(event.target.checked == false){
                                                        setValue("account_sfid","");
                                                    }
                                                }} />} checked={field.value} label="取引先コピー" labelPlacement="end" />
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            control={control}
                                            name="account_sfid"
                                            render={({ field }) => (
                                                <>
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="取引先SFID"
                                                        size="small"
                                                        inputProps={{
                                                            readOnly: true
                                                        }}
                                                        sx={{bgcolor: "floralwhite"}}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                            </>               
                        }
                    </Stack>
                    
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ width:"98%" ,mt: 2,marginLeft:"10px"}}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};

export default CustomersEdit;