import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { PageData } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { MonitoringOperatorCustomerRoleValue } from '../../../statics';
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import palette from '../../../theme/palette';
import FetchHelper from "../../FetchHelper";
import AlertDialog from '../../common/AlertDialog';
import BaseAdd from './BaseAdd';
import BaseEdit from './BaseEdit';
import BaseGrid from './BaseGrid';

// -------------
// FETCH ID
const getAllBases = "GET_ALL_BASES";
const insertBases = "INSERT_BASES";
const updateBases = "UPDATE_BASES";
const deleteBases = "DELETE_BASES";
const formId = "CUSTOMERS_SEARCH_FORM";
const menusFetchId = 'MENUS_FETCH';
const strInsert = "insert";
const strUpdate = "update";
const strDelete = "delete";
const getAllGroupCustomer = "getAllGroupCustomer";

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
    "keyword": string;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 0,
    keyword: '',
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// -------------
// Props
export type CustomersListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const BaseList = (props: CustomersListProps) => {
    const {
        fetchStateMenu,
        onGetAuthRole,
        onSearch,
        onGetGroupCustomer,
        onInsertCustomer,
        onUpdateCustomer,
        onDeleteCustomer,
        onCreateSuccess,
        onUpdateSuccess,
        onDeleteSuccess,
        onError,
        onCreateError,
        onUpdateError,
        onDeleteError,
    } = props;

    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    localStorage.setItem('lastActiveFormPath', RoutePath.BaseList);
    localStorage.setItem('title', `拠点`);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<any[]>([]);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectData, setSelectData] = React.useState<any>(null);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [disableDelete, setDisableDelete] = React.useState(true);
    const [colState, setColState] = useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [customerGroupOption,setCustomerGroupOption] = React.useState<any>([]);
    const [authRole, setAuthRole] = React.useState<any>(null);
    const [loadingRole,setLoadingRole] = React.useState<any>(authRole == null);
    const gridRef = useRef() as any;
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.keyword) params.set("keyword", value.keyword);

        setLoadingFlag(true);
        onSearch(params);
    }

    // 初回検索実行
    React.useEffect(() => {
        const params = new Map<string, any>();
        params.set("limit",50);
        params.set("page",0);
        onGetGroupCustomer(params);
        if(fetchStateMenu && fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (gridRef && gridRef.current) {
            gridRef.current.setRowData(listData);
        }
    },[listData])

    // 検索正常終了時
    const handleSuccessGetAll = (data: any) => {
        console.log('>>> handleSuccessGetAll');
        setDisableDelete(true);
        setDisableEdit(true);
        if (data && data.result_code == 0) {
            const propertiesArray:any = Object.entries(data.results.base_info).map(([key, value] : any) => ({ group_id:key, values: value }));
            const temp_Array:any = [];
            propertiesArray.forEach((item:any) => {
                item.values.base_id_array.forEach((data:any) => {
                    const object:any = { group_id: item.group_id, base_id: null, base_name: ""};
                    object.base_id = data.base_id;
                    object.base_number = data.base_number;
                    object.base_name = data.base_name;
                    object.isSelect = false;
                    temp_Array.push(object);
                })
            });

            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(temp_Array);
            }

            setListData(temp_Array);

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
        setLoadingFlag(false);
    };

    // 検索正常終了時
    const handleSuccess = (data: any, type: string) => {
        console.log('>>> handleSuccess');
        if (data.result_code === 0) {
            switch (type) {
                case strInsert:
                    setOpenAddDialog(false);
                    onCreateSuccess();
                    break;
                case strUpdate:
                    setOpenEditDialog(false);
                    onUpdateSuccess();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteSuccess();
                    break;
            }
            onGetAuthRole();
            handleSearchSubmit(getValues());
            setDisableEdit(true);
            setDisableDelete(true);
        }
        else {
            switch (type) {
                case strInsert:
                    onCreateError();
                    break;
                case strUpdate:
                    onUpdateError();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteError();
                    break;
                default:
                    break;
            }
            setLoadingFlag(false);
        }
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        onError(success, data, error);
        setLoadingFlag(false);
        // gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    React.useEffect(() => {
        // グラフ表示用データの更新
        // --------------------------------
        if (myParam) {
            listData.forEach((item: any) => {
                item.serial_no = myParam
            });
            setListData(() => [...listData])
        }
        else {
            listData.forEach((item: any) => {
                item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            });
            setListData(() => [...listData])
        }
        // eslint-disable-next-line
    }, [myParam]);

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    // 追加の処理
    const handleAdd = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        handleOpenAddDialog();        
    }

    // 編集の処理
    const handleEdit = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        handleOpenEditDialog();
    }

    //削除の処理
    const handleDelete = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        if (listData.filter((item) => item.isSelect === true).length === 0) {
            return;
        }
        const deleteNames = listData.filter((item) => item.isSelect === true).map((item) => item.base_name);
        const msg = `拠点：` + deleteNames.join(', ') + "を削除しますか？"
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        listData.filter((item) => item.isSelect === true).forEach((item) => {
            onDeleteCustomer(item.base_id.toString());
        })
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    }

    // 検索正常終了時
    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        let list = listData.filter((item) => item.base_id === data.base_id)
        list[0].isSelect = !list[0].isSelect;

        const list_selected = listData.filter((item) => item.isSelect === true);
        let selectCount = list_selected.length;
        if (selectCount === 1) {
            if(authRole && Number(authRole.role) === Number(MonitoringOperatorCustomerRoleValue) && list_selected.some((item:any) => Number(item.group_id) != Number(authRole["company_group_id"]))){
                setDisableEdit(true);
                setDisableDelete(true);
            }else{
                setDisableEdit(false);
                setDisableDelete(false);
            }
        } else if (selectCount > 0) {
            if(authRole && Number(authRole.role) === Number(MonitoringOperatorCustomerRoleValue)
                        && list_selected.some((item:any) => Number(item.group_id) != Number(authRole["company_group_id"]))){
                setDisableEdit(true);
                setDisableDelete(true);
            }else{
                setDisableEdit(true);
                setDisableDelete(false);
            }
        } else {
            setDisableEdit(true);
            setDisableDelete(true);
        }
    };

    // 帳票ダイアログを表示する
    const handleOpenAddDialog = () => {
        console.log('>>> handleOpenAddDialog');
        // setSelectData(initialCustomers);
        setOpenAddDialog(true);
    };

    // 帳票ダイアログを無効にする
    const handleOpenAddDialogCancel = () => {
        console.log('>>> handleOpenAddDialogCancel');
        setOpenAddDialog(false);
    };

    // 帳票ダイアログを表示する
    const handleOpenEditDialog = () => {
        const baseSelect = listData.filter((item) => item.isSelect === true)[0];
        setSelectData(baseSelect);
        setOpenEditDialog(true);
    };

    // 帳票ダイアログを無効にする
    const handleOpenEditDialogCancel = () => {
        console.log('>>> handleOpenEditDialogCancel');
        setOpenEditDialog(false);
    };

    const handleInsert = (data: any) => {
        setLoadingFlag(true);
        onInsertCustomer(data);
    };

    const handleUpdate = (data: any) => {
        setLoadingFlag(true);
        onUpdateCustomer(data);
    };

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("keyword", '')
    };

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    const handleSuccessGetAllGroupCustomer = (data:any) => {
        setLoadingFlag(false);
        if(data && data.result_code == 0){
            setCustomerGroupOption(data.results.map((item:any) => {return {value : item.group_id,label:item.name}}));
            handleSearchSubmit(getValues());
        }else{
            setLoadingFlag(false);
        }
    }

    const handleErrorGetAllGroupCustomer = (error:any) => {
        setLoadingFlag(false);
    }

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            setAuthRole(data);
            setLoadingRole(false);
        }
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                {(loadingFlag || loadingRole) && (
                    <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                        <CircularProgress />
                    </div>
                )}
                {openDeleteDialog &&
                    <AlertDialog title={"拠点削除"} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} />
                }
                {openAddDialog &&
                    <Dialog open={openAddDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>{`拠点追加`}</DialogTitle>
                        <DialogContent sx={{ pb: 1.5,pt:0.5 }}>
                            {customerGroupOption.length > 0 && 
                                <BaseAdd
                                    onOK={handleInsert}
                                    onCancel={handleOpenAddDialogCancel}
                                    customerGroupOption={customerGroupOption}
                                    authRole={authRole}
                                />
                            }
                        </DialogContent>
                    </Dialog>
                }
                {
                    openEditDialog &&
                    <Dialog open={openEditDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>{`拠点編集`}</DialogTitle>
                        <DialogContent sx={{ pb: 1.5,pt:0.5 }}>
                            {
                                customerGroupOption.length > 0 && 
                                <BaseEdit
                                    onOK={handleUpdate}
                                    onCancel={handleOpenEditDialogCancel}
                                    customerGroupOption={customerGroupOption}
                                    authRole={authRole}
                                    inititalBaseData={selectData}
                                />
                            }                            
                        </DialogContent>
                    </Dialog>
                }
                <div>
                    <form onSubmit={handleSubmit(handleSearch)}>
                        <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                            <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                                <div style={{ display: 'inline-block' }}>
                                    <Typography variant="h6" >検索条件</Typography>
                                </div>
                                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                    {
                                        openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                    }
                                </div>
                            </div>
                            <div style={{ marginLeft: '10px', marginRight: '10px', marginBottom: "10px" }}>
                                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems={"end"}
                                        spacing={1}
                                        direction="row"
                                        style={{ alignItems: 'center' }}
                                    >
                                        <Grid item>
                                            <Stack direction="row">
                                                <Controller
                                                    name="keyword"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            label="検索キーワード"
                                                            size="small"
                                                            style={{ background: palette.background.paper }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                        </Grid>
                                        <Grid item >
                                            <Button variant="contained" onClick={handleSearch}>検索</Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </div>
                        </Stack>
                    </form >
                </div>
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} mb={1} spacing={1}>
                        <Button variant="contained" size="medium" onClick={handleAdd}>{<AddIcon />}</Button>
                        <Button variant="contained" size="medium" onClick={handleEdit} disabled={disableEdit}>{<EditIcon />}</Button>
                        <Button variant="contained" size="medium" onClick={handleDelete} disabled={disableDelete}>{<ClearIcon />} </Button>
                    </Stack>
                </div>
                <div>
                    {
                        customerGroupOption.length > 0 && 
                        <BaseGrid
                            ref={gridRef}
                            colState={colState}
                            pageSize={getValues('limit')}
                            pageData={pageData}
                            onChangeSearchCondition={handleChangePageData}
                            onSelect={handleSelect}
                            openSearch={openSearch}
                            authRole={authRole}
                            customerGroupOption={customerGroupOption}
                        />
                    }
                    
                </div>
            </div>
            <FetchHelper fetchId={getAllGroupCustomer} onComplete={(success, data, error) =>
                success ? handleSuccessGetAllGroupCustomer(data) : handleErrorGetAllGroupCustomer(error)} />
            <FetchHelper fetchId={getAllBases} onComplete={(success, data, error) =>
                success ? handleSuccessGetAll(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={insertBases} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strInsert) : handleError(success, data, error)} />
            <FetchHelper fetchId={updateBases} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strUpdate) : handleError(success, data, error)} />
            <FetchHelper fetchId={deleteBases} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strDelete) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        getAllBases
    ) as FetchState,
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onGetGroupCustomer: (params: { [key: string]: any }) => actionCreators.fetch(getAllGroupCustomer,`/master/customer-groups/get`,"GET",params),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(getAllBases, `/master/bases/get`, "GET", params),
    onInsertCustomer: (data: any) => actionCreators.fetch(insertBases, `/master/bases/add`, "POST", data, false, true),
    onUpdateCustomer: (data: any) => actionCreators.fetch(updateBases, `/master/bases/edit`, "POST", data, false, true),
    onDeleteCustomer: (id: string) => actionCreators.fetch(deleteBases, `/master/bases/delete?id=${id}`, "GET", null),
    onChangePageSize: (pageSize: number) => actionCreators.setPageSize(pageSize),
    onCreateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: `拠点情報追加`,
            body: `拠点情報を登録しました。`,
        }),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: `拠点情報編集`,
            body: `拠点情報を更新しました。`,
        }),
    onDeleteSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: `拠点情報削除`,
            body: `拠点情報を削除しました。`,
        }),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: `拠点情報`,
            body: `${error ? "拠点情報を取得できません。" + error : "拠点情報を取得できません。"}`,
        }),
    onCreateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: `拠点情報追加`,
            body: `拠点情報が登録出来ませんでした。`,
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: `拠点情報編集`,
            body: `拠点情報が更新出来ませんでした。`,
        }),
    onDeleteError: () =>
        actionCreators.showMessage({
            type: "error",
            title: `拠点情報削除`,
            body: `拠点情報が削除出来ませんでした。`,
        }),
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BaseList as any);
