import { Button, DialogActions, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { padZeroToStart } from '../../utils';

export type SelectRelayZCTSettingDialogProps = {
    isOpen: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
    data: any;
    dialogTitle: string;
};

interface formProps{
    zctType1:string;
    zctType2:string;
    zctType3:string;
    zctType4:string;
    zctType5:string;
    zctType6:string;
    ctRatio1:string;
    ctRatio2:string;
    ctRatio3:string;
    ctRatio4:string;
    ctRatio5:string;
    ctRatio6:string;
}

const RELAY_ZCT_OPTION = [
    {
        label: "4500：1",
        value: "0001"
    },
    {
        label: "2000：1",
        value: "0002"
    },
    {
        label: "OTG-AA42",
        value: "0003"
    },
    {
        label: "CTF-05A",
        value: "0004"
    },
]

export const SelectRelayZCTSettingDialog = (props: SelectRelayZCTSettingDialogProps) => {
    const { isOpen, onOK, onCancel, data, dialogTitle } = props;
    const [open, setOpen] = React.useState(isOpen);

    const { setValue, getValues, control, formState: { errors } } = useForm<formProps>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: data,
    });

    const handleInputOnBlur = (e: any, name: any, maxLength: number) => {
        setValue(name, padZeroToStart(e.target.value, maxLength));
    }
    
    // Cancelボタンイベント
    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const handleUpdate = () => {
        const params = {
            device_no: data.deviceNo,
            mainid: data.mainid,
            zct_type1: getValues("zctType1"),
            zct_type2: getValues("zctType2"),
            zct_type3: getValues("zctType3"),
            zct_type4: getValues("zctType4"),
            zct_type5: getValues("zctType5"),
            zct_type6: getValues("zctType6"),
            ct_ratio1: getValues("ctRatio1"),
            ct_ratio2: getValues("ctRatio2"),
            ct_ratio3: getValues("ctRatio3"),
            ct_ratio4: getValues("ctRatio4"),
            ct_ratio5: getValues("ctRatio5"),
            ct_ratio6: getValues("ctRatio6"),
        }
        setOpen(false);
        onOK(params);
    };

    return (
        <div>
            <Dialog open={open} maxWidth={"md"} fullWidth>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                <Stack spacing={2.5} style={{ marginLeft: '10px', width: '98%', position:"relative" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth  error={!!errors?.zctType1}>
                                <InputLabel>ZCT/Relay種類1</InputLabel>
                                <Controller
                                    control={control}
                                    name="zctType1"
                                    rules={{ required: '必須項目です。選択してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                error={!!errors?.zctType1}
                                                label={"ZCT/Relay種類1"}
                                                onChange={(event) => {                                                        
                                                    field.onChange(event);
                                                }}
                                            >
                                                {RELAY_ZCT_OPTION.map((item:any) => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                !!errors?.zctType1 &&
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors?.zctType1?.message}
                                                </FormHelperText>
                                            }
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="ctRatio1"
                                rules={{ required: '必須項目です。入力してください。',
                                    pattern: {
                                        value: /^[0-9\b]+$/,
                                        message: '数字を入力してください。'
                                    }
                                }}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label={`CT比1`}
                                            size="small"
                                            error={!!errors?.ctRatio1}
                                            helperText={errors?.ctRatio1?.message}
                                            inputProps={{
                                                maxLength:4
                                            }}
                                            onBlur={(e) => handleInputOnBlur(e, 'ctRatio1', 4)}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth  error={!!errors?.zctType2}>
                                <InputLabel>ZCT/Relay種類2</InputLabel>
                                <Controller
                                    control={control}
                                    name="zctType2"
                                    rules={{ required: '必須項目です。選択してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                error={!!errors?.zctType2}
                                                label={"ZCT/Relay種類2"}
                                                onChange={(event) => {                                                        
                                                    field.onChange(event);
                                                }}
                                            >
                                                {RELAY_ZCT_OPTION.map((item:any) => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                !!errors?.zctType2 &&
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors?.zctType2?.message}
                                                </FormHelperText>
                                            }
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="ctRatio2"
                                rules={{ required: '必須項目です。入力してください。',
                                    pattern: {
                                        value: /^[0-9\b]+$/,
                                        message: '数字を入力してください。'
                                    }
                                }}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label={`CT比2`}
                                            size="small"
                                            error={!!errors?.ctRatio2}
                                            helperText={errors?.ctRatio2?.message}
                                            inputProps={{
                                                maxLength:4
                                            }}
                                            onBlur={(e) => handleInputOnBlur(e, 'ctRatio2', 4)}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth  error={!!errors?.zctType3}>
                                <InputLabel>ZCT/Relay種類3</InputLabel>
                                <Controller
                                    control={control}
                                    name="zctType3"
                                    rules={{ required: '必須項目です。選択してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                error={!!errors?.zctType3}
                                                label={"ZCT/Relay種類3"}
                                                onChange={(event) => {                                                        
                                                    field.onChange(event);
                                                }}
                                            >
                                                {RELAY_ZCT_OPTION.map((item:any) => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                !!errors?.zctType3 &&
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors?.zctType3?.message}
                                                </FormHelperText>
                                            }
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="ctRatio3"
                                rules={{ required: '必須項目です。入力してください。',
                                    pattern: {
                                        value: /^[0-9\b]+$/,
                                        message: '数字を入力してください。'
                                    }
                                }}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label={`CT比3`}
                                            size="small"
                                            error={!!errors?.ctRatio3}
                                            helperText={errors?.ctRatio3?.message}
                                            inputProps={{
                                                maxLength:4
                                            }}
                                            onBlur={(e) => handleInputOnBlur(e, 'ctRatio3', 4)}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth  error={!!errors?.zctType4}>
                                <InputLabel>ZCT/Relay種類4</InputLabel>
                                <Controller
                                    control={control}
                                    name="zctType4"
                                    rules={{ required: '必須項目です。選択してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                error={!!errors?.zctType4}
                                                label={"ZCT/Relay種類4"}
                                                onChange={(event) => {                                                        
                                                    field.onChange(event);
                                                }}
                                            >
                                                {RELAY_ZCT_OPTION.map((item:any) => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                !!errors?.zctType4 &&
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors?.zctType4?.message}
                                                </FormHelperText>
                                            }
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="ctRatio4"
                                rules={{ required: '必須項目です。入力してください。',
                                    pattern: {
                                        value: /^[0-9\b]+$/,
                                        message: '数字を入力してください。'
                                    }
                                }}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label={`CT比4`}
                                            size="small"
                                            error={!!errors?.ctRatio4}
                                            helperText={errors?.ctRatio4?.message}
                                            inputProps={{
                                                maxLength:4
                                            }}
                                            onBlur={(e) => handleInputOnBlur(e, 'ctRatio4', 4)}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth  error={!!errors?.zctType5}>
                                <InputLabel>ZCT/Relay種類5</InputLabel>
                                <Controller
                                    control={control}
                                    name="zctType5"
                                    rules={{ required: '必須項目です。選択してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                error={!!errors?.zctType5}
                                                label={"ZCT/Relay種類5"}
                                                onChange={(event) => {                                                        
                                                    field.onChange(event);
                                                }}
                                            >
                                                {RELAY_ZCT_OPTION.map((item:any) => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                !!errors?.zctType5 &&
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors?.zctType5?.message}
                                                </FormHelperText>
                                            }
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="ctRatio5"
                                rules={{ required: '必須項目です。入力してください。',
                                    pattern: {
                                        value: /^[0-9\b]+$/,
                                        message: '数字を入力してください。'
                                    }
                                }}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label={`CT比5`}
                                            size="small"
                                            error={!!errors?.ctRatio5}
                                            helperText={errors?.ctRatio5?.message}
                                            inputProps={{
                                                maxLength:4
                                            }}
                                            onBlur={(e) => handleInputOnBlur(e, 'ctRatio5', 4)}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl size="small" fullWidth  error={!!errors?.zctType6}>
                                <InputLabel>ZCT/Relay種類6</InputLabel>
                                <Controller
                                    control={control}
                                    name="zctType6"
                                    rules={{ required: '必須項目です。選択してください。' }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                error={!!errors?.zctType6}
                                                label={"ZCT/Relay種類6"}
                                                onChange={(event) => {                                                        
                                                    field.onChange(event);
                                                }}
                                            >
                                                {RELAY_ZCT_OPTION.map((item:any) => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                !!errors?.zctType6 &&
                                                <FormHelperText style={{ color: 'red' }}>
                                                    {errors?.zctType6?.message}
                                                </FormHelperText>
                                            }
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="ctRatio6"
                                rules={{ required: '必須項目です。入力してください。',
                                    pattern: {
                                        value: /^[0-9\b]+$/,
                                        message: '数字を入力してください。'
                                    }
                                }}
                                render={({ field }) => (
                                    <>
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label={`CT比6`}
                                            size="small"
                                            error={!!errors?.ctRatio6}
                                            helperText={errors?.ctRatio6?.message}
                                            inputProps={{
                                                maxLength:4
                                            }}
                                            onBlur={(e) => handleInputOnBlur(e, 'ctRatio6', 4)}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    <Button variant="contained" onClick={handleUpdate}>保存</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SelectRelayZCTSettingDialog;